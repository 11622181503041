import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { 
      path: '*', 
      redirect: '/404' 
    },   
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/transactions",
      name: "transactions",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/stores",
      name: "stores",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/facture",
      name: "facture",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/result",
      name: "result",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/form",
      name: "form",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/play",
      name: "play",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Home.vue")
  },
    {
      path: "/dotations",
      name: "dotations",
          component: () => import("./views/Home.vue")
    },
    {
      path: "/mentions",
      name: "mentions",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/produits",
      name: "produits",
      component: () => import("./views/Home.vue")
    }
  ],
});

export default router;
