<template>
  <footer class="bg-white">
   <!-- <ul
      class=" leading-7 lg:leading-4  lg:flex md:justify-between lg:items-center h-10 font-bold max-w-7xl m-auto px-2 text-center"
    >
      <li class="my-3 md:my-0">
        <a
          class="hover:text-turquoise text-md lg:text-base 2xl:text-xl block lg:pt-0"
          href="https://oxoformdocs.fra1.digitaloceanspaces.com/64c21d159b95c2a9326ca1f4/reglement.pdf"
          target="_blank"
          >Réglement</a
        >
      </li>
      <li class="mb-3 md:mb-0">
        <a
          class="hover:text-turquoise text-md lg:text-base 2xl:text-xl block lg:pt-0"
          href="https://oxoformdocs.fra1.digitaloceanspaces.com/64c21d159b95c2a9326ca1f4/politique.pdf"
          target="_blank"
          >Politique de confidentialité</a
        >
      </li>
      <li class="mb-3 md:mb-0">
        <router-link
          class="hover:text-turquoise text-md lg:text-base 2xl:text-xl block lg:pt-0"
          to="/mentions"
          >Mentions légales</router-link
        >
      </li>
      <li class="mb-3 md:mb-0">
        <router-link
          class="hover:text-turquoise text-md lg:text-base 2xl:text-xl block lg:pt-0"
          to="/contact"
          >Contactez-nous</router-link
        >
      </li>
      <li
        class="flex gap-2 items-center justify-center pb-6 md:pb-0 pt-3 lg:pt-0"
      >
        <a
          href="https://www.linkedin.com/company/pacificetgeneralfrance/"
          target="_blank"
          ><i
            class="fa-brands fa-linkedin-in flex items-center justify-center text-white bg-gris rounded-full text-xl lg:text-xl p-1 h-[31px] w-[31px] hover:bg-turquoise cursor-pointer"
          ></i
        ></a>
        <a href="https://www.facebook.com/PacificGeneralFrance" target="_blank"
          ><i
            class="fa-brands fa-facebook text-3xl lg:text-3xl flex items-center justify-center p-1 h-[32px] w-[32px] hover:text-turquoise cursor-pointer"
          ></i
        ></a>
        <a href="https://www.instagram.com/pacificetgeneral/" target="_blank"
          ><i
            class="fa-brands fa-instagram flex items-center justify-center text-white bg-gris rounded-full text-xl lg:text-xl p-1 h-[31px] w-[31px] hover:bg-turquoise cursor-pointer"
          ></i
        ></a>
      </li>
    </ul> -->
  </footer>
</template>

<script>
export default {
  
}
</script>
