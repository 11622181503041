<template>
  <header
    class="bg-white lg:h-[100px] 2xl:h-[115px] shade relative w-full z-20"
  >
    <button
      class="absolute w-12 right-1 top-2 text-3xl lg:hidden"
      @click="toggle"
    >
      <img src="@/assets/images/burger_mobile.svg" />
    </button>
    <!-- <Drawer @close="toggle" align="right" :closeable="true">
      <div v-if="open">
        <a href="https://www.pacific-france.com/" target="_blank"
          ><img
          @click="toggle"
          src="@/assets/images/logo.png"
          class="m-auto w-[250px] mt-2"
        /></a>
        <div>
          <ul class="px-2 text-center pt-8">
            <li class="my-8" @click="toggle">
              <router-link class="button  font-bold" to="/produits"
                ><span>Les produits éligibles</span></router-link
              >
            </li>
            <li class="my-8" @click="toggle">
              <router-link class="button font-bold" to="/dotations"
                ><span>Les dotations</span></router-link
              >
            </li>
            <li class="my-8" @click="toggle">
              <router-link class="button font-bold" to="/stores"
                ><span>Trouver un distributeur</span></router-link
              >
            </li>
          </ul>
        </div>

        
        <img src="@/assets/images/22-min.png" class="m-auto w-[200px] py-6" />
      </div>
    </Drawer> -->

    <div
      class="max-w-7xl m-auto flex justify-between items-center lg:h-[100px] 2xl:h-[115px]"
    >
      <div class="w-full lg:w-auto">
        <a href="https://www.pacific-france.com/" target="_blank">
          <img
            class="w-[280px] 2xl:w-[300px] hidden lg:block m-auto ml-10"
            src="@/assets/images/logo.png"
          />
          <img
            class="w-[180px] sm:w-[230px] pt-4 lg:hidden m-auto mb-4"
            src="@/assets/images/logo.png"
          />
          <img
            class="w-[180px] sm:w-[220px] pt-0 pb-4 lg:hidden m-auto mb-4"
            src="@/assets/images/22-min.png"
          />
        </a>
      </div>
      <div>
      <!--  <ul
          class="hidden lg:flex px-16 justify-between items-center sticker h-[100px] 2xl:h-[130px]"
        >
          <li class="pr-4">
            <router-link class="button font-bold" to="/produits"
              ><span>Les produits éligibles</span></router-link
            >
          </li>
          <li class="pr-4">
            <router-link class="button font-bold" to="/dotations"
              ><span>Les dotations</span></router-link
            >
          </li>
          <li>
            <router-link class="button font-bold" to="/stores"
              ><span>Trouver un distributeur</span></router-link
            >
          </li>
        </ul> -->
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "app",
  computed: {
    currentRouteName() {
      
      return this.$route.name;

    },
  },
  watch : {
    currentRouteName() {
      if(window._id && window._secu) {
    //    this.homelink = '/status/'+window._id+'/'+window._secu
      }
    }
  },
  data() {
    return {
      homelink : '/',
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
